$secondary: #abaeaf;
$success: #e9e9e9;
$gray: #676a6a;
$darkGray: #272b2b;
$black: #000;
$iconsColor: #b6b9bd;
$google: #ca4536;
$facebook: #60759e;
$twitter: #00acee;
$whatsapp: #40c229;
