.header {
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;

    li {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
