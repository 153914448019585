@import 'utils/styles/scss/variables';
@import '~bootstrap/scss/bootstrap';
@import '~font-awesome/css/font-awesome.min.css';
$theme-colors: (
  'gray': $gray,
);
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Poppins', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins';
}
p {
  font-family: 'Droid Sans';
  font-size: 1.2rem;
  color: $gray;
}
.display-4 {
  font-size: 3rem;
}
.btn-outline-secondary {
  &:hover {
    color: #ffffff;
  }
}
.w-12 {
  width: 12% !important;
}

@import 'utils/styles/scss/homePage';

.card {
  border-radius: 0;
  * {
    border-radius: 0;
  }

  .card-header,
  .card-footer {
    background-color: transparent;
  }
}

button.btn {
  border-radius: 0;
  text-transform: uppercase;
  border-width: 3px;
  font-weight: bold;
  letter-spacing: 1px;
}

button.outline {
}

.form-control:focus {
  border-color: #c9dc8d;
  box-shadow: 0 0 5px #c9dc8d;
}

.file-form:focus,
.file-form:active,
.file-form:hover {
  border-color: #c9dc8d;
  box-shadow: 0 0 5px #c9dc8d;
}

.custom .custom-file {
  border-radius: 0;
  .custom-file-input {
    border-radius: 0;
  }
  .custom-file-label {
    border-radius: 0;
  }
}

.dropdown-menu {
  border-radius: 0;
}

a:link {
  color: #000;
  text-decoration: none;
}
a:visited {
  color: #000;
  text-decoration: none;
}
a:active {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: #000;
  text-decoration: underline;
}

.side-menu {
  width: 25%;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: -25%;
  transition: right linear 0.2s 0s;

  &.show {
    right: 0;
  }
}

@media (max-width: 768px) {
  .side-menu {
    width: 65%;
    right: -65%;
  }
}

@media (max-width: 576px) {
  .side-menu {
    width: 100%;
    right: -100%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  /* Positioning */
  position: fixed; /* or 'absolute' if you don't want it to stick at the top on scroll */
  top: 15%; /* Adjust this value as needed to move it down from the top */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
}
